import * as React from "react"
import { DocumentsT, UploadedFileT } from "types/Common"
import SupportCategoryTitle from "components/atoms/SupportCategoryTitle"
import Grid from "components/PageBuilder/Common/Grid"
import { IconsId } from "assets/fonts/iconfont/icons"
import Box from "components/molecules/Box"
import Icon from "components/atoms/Icon"
import { getFile } from "utilities/Image"

type Props = {
  categoryTitle: string
  categoryIcon?: IconsId
  documents: DocumentsT
}

const SupportDownloadsList: React.ComponentType<Props> = ({
  documents,
  categoryTitle,
  categoryIcon,
}) => {
  if (documents.length === 0) {
    return null
  }

  return (
    <div className={"my-10"}>
      <SupportCategoryTitle title={categoryTitle} icon={categoryIcon} />
      <div className={"m-10"}>
        <Grid xs={1} sm={2} lg={3} gap={'22px'}>
          {documents.map(document => {
            return (
              <Box
                tag={"a"}
                link={getFile(document.file)}
                outline
                visibleIcon
                key={document.id}
                icon={"download"}
              >
                <div className={"flex items-center"}>
                  <Icon icon={"pdf"} color={"text-quantcomBlue"} />
                  <div className={"ml-2"}>{document.name}</div>
                </div>
              </Box>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default SupportDownloadsList
