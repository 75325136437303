import * as React from "react"
import SEO from "components/seo"
import { PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import Container from "../components/atoms/Container"
import BlockTitle from "../components/atoms/BlockTitle"
import styled from "styled-components"
import SupportContact from "components/atoms/SupportContact"
import SupportDownloadsList from "components/molecules/SupportDownloadsList"
import { useTranslation } from "react-i18next"

const Title = styled(BlockTitle)`
  margin-bottom: 0.5em;
`
type Props = {
  pageContext: PageContextT
  location: Location
}

const SupportServices: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      documentCategories,
      documents,
      configurations,
      mobileHeaderLinks,
      localizedPage,
    },
  } = props
  const { t, i18n } = useTranslation()
  const supportLink = i18n.language === "en" ? "/en/support" : "/podpora"
  const breadcrumbs = [
    {
      title: t("support"),
      route: supportLink,
    },
    {
      title: page.title,
    },
  ]

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      breadcrumbs={breadcrumbs}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={["/podpora", "/en/support"]}
      localizedPage={localizedPage}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />

      <Container className={"sm:mt-20 mt-16 mb-20"} mobileNoPadding>
        <Title title={page.title} align={"left"} className={"sm:px-0 px-4"} />
        {documentCategories.map((item, index) => {
          const foundDocuments = documents.filter(
            a => a.document_category.id === item.id
          )
          return (
            <SupportDownloadsList
              documents={foundDocuments}
              categoryTitle={item.name}
              key={index}
            />
          )
        })}
        <SupportContact
          backButtonLink={supportLink}
          backButtonTitle={t("backToSupport")}
        />
      </Container>
    </Layout>
  )
}

export default SupportServices
